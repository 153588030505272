import React from "react"
import { Grid, Box, Text, Image, Divider } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import NewsletterSignup from "../components/NewsletterSignup"

import image01 from "../images/news/2021-roadmap/01.jpg"
import image02 from "../images/news/2021-roadmap/02.jpg"
import image03 from "../images/news/2021-roadmap/03.jpg"
import image04 from "../images/news/2021-roadmap/04.jpg"
import image05 from "../images/news/2021-roadmap/05.jpg"
import image09 from "../images/news/2021-roadmap/09.jpg"
import image08 from "../images/news/2021-roadmap/08.jpg"
import image10 from "../images/news/2021-roadmap/10.jpg"

const RoadmapPage = () => {
  return (
    <Layout>
      <SEO title="New in 2021" />

      <Breadcrumbs title="New in 2021" headline="Our world is expanding" />

      <Grid variant="textWrap">
        <Box>
          <Text as="p" variant="body">We're building for the future of Quest, and 2021 will be our biggest year yet. Our next two Kickstarter campaigns will bring new creatures, treasures, and an entirely new edition of the game.</Text>

          <Box sx={{ bg: "grayXLight", p: [3, 5], my: [5, 6] }}>
            <NewsletterSignup />
          </Box>

          <Text as="p" variant="label" sx={{ m: 0 }}>Early 2021</Text>
          <Text as="h2" variant="title02">Characters & Treasures</Text>
          <Text as="p" variant="body">On February 1st, 2021, we'll launch our next Kickstarter campaign to raise funding for four major new products, including two new books and two companion card decks.</Text>
          <Text as="p" variant="body">We’re making a greatly expanded <Text sx={{ display: "inline", fontWeight: "bold" }}>Character Catalog</Text>—up from 22 to 100 characters. The Quest Character Catalog will be a 6x9" hardcover book and a companion card deck with tarot-sized cards for each creature. The catalog is illustrated by <Text as="a" variant="link" href="https://twitter.com/grimwilkins">Grim Wilkins</Text>, who provided all of the amazing color illustration in the Quest Game Book.</Text>
          <Text as="p" variant="body">The Quest Character Catalog will add 78 new characters to the game. These characters each come with their own story background, stats, special features, and descriptions that you can read aloud at the table.</Text>
          <Text as="p" variant="body">From terrifying monsters to friendly companions and worthy adversaries, the catalog has a balance of menacing creatures to fight and characters worth chatting and developing relationships with. In addition to creature archetypes, the catalog includes a number of named characters who you can use to interact with the players in deeper ways.</Text>
        </Box>
      </Grid>
      
      <Image src={image01} sx={{ mt: [7, 8] }} />
      
      <Grid variant="base" columns={[1, 2]} sx={{ my: [7, 8] }}>
        <Image src={image02} />
        <Image src={image03} />
        <Image src={image04} />
        <Image src={image05} />
      </Grid>

      <Grid variant="textWrap">
        <Box>
          <Text as="p" variant="body">We’re also doubling the contents of the <Text sx={{ display: "inline", fontWeight: "bold" }}>Treasure Catalog</Text>, with 100 brand-new items, from uncommon curiosities to magical artifacts of unbelievable power. The Quest Treasure Catalog will also be a 6x9" hardcover book and a companion card deck with tarot-sized cards for each item. The catalog is illustrated by <Text as="a" variant="link" href="https://twitter.com/binwizard">Marianna Learmonth</Text>, who provided all of the wondrous illustration in Quest’s original treasure catalog.</Text>
          <Text as="p" variant="body">The Treasure Book will contain the full detail for all 200 items, including amazing new illustrations from Marianna. The book will also contain additional guidance about how to use items effectively in your story and how to create your own compelling magic items and curiosities.</Text>
          <Text as="p" variant="body"></Text>
          <Text as="p" variant="body"></Text>
          <Text as="p" variant="body"></Text>
          <Text as="p" variant="body"></Text>
          <Text as="p" variant="body"></Text>
          <Text as="p" variant="body"></Text>
        </Box>
      </Grid>

      <Grid variant="base" columns={[1, 2]} sx={{ my: [7, 8] }}>
        <Image src={image08} />
        <Image src={image09} />
        <Image src={image10} />
      </Grid>

      <Grid variant="textWrap">
        <Box>
          <Text as="p" variant="label" sx={{ m: 0 }}>Later in 2021</Text>
          <Text as="h2" variant="title02">Quest: Cosmic Fantasy Edition</Text>
          <Text as="p" variant="body">The second campaign, which will take place in the back half of the year, will be for our first major expansion: the Cosmic Fantasy Edition. This edition of the game is based on sci-fi and space fantasy, with seven exciting new roles, new role-neutral abilities, and a starship catalog.</Text>
          <Text as="p" variant="body">The Cosmic Fantasy Edition will use Quest’s same core rules, but we’ll be adding a few new rules to drive storytelling in a galactic setting, including a starship system that allows you to modify your ship and use it like an extension of your role’s special abilities.</Text>
          <Text as="p" variant="body">This edition will fully stand alone, and you won’t need any of Quest’s original products to play it. But because the Cosmic Fantasy Edition will use the same core rules as the original game (what we’re now calling the Classic Fantasy Edition), you’ll be able to mix and match all 15 of Quest’s roles. Yes — that means you can take your Invoker on a ship that travels the stars.</Text>
        </Box>
      </Grid>

      <Grid variant="base">
        <Divider />
      </Grid>

      <Grid variant="textWrap">
        <Box>
          <Text as="h2" variant="title02">New Kickstarter Process</Text>
          <Text as="p" variant="body">We’re doing things a bit differently this time after learning a lot about Kickstarter from our first campaign in 2018.</Text>
          
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>No stretch goals</Text>
          <Text as="p" variant="body">We work best when we focus on only a few products at a time, which allows us to make sure they come out great. Stretch goals work for a lot of Kickstarter campaigns, but we’re going to concentrate on making the best characters and treasures out there, in products that we hope you’ll genuinely enjoy touching and reading. If our funding greatly exceeds the target, we’ll be able to work on even more things behind the scenes at our own pace and surprise you with new stuff, like these two catalogs.</Text>

          <Text as="h3" variant="title03" sx={{ mt: 3 }}>Designed in advance to fulfill quickly</Text>
          <Text as="p" variant="body">Since we’re writing, designing, and illustrating the catalogs in advance, we’ll be ready to go to with manufacturing as soon as the project is funded. We’re working with the same great vendors we worked with for our initial set of products. Rewards should be available in 3–4 months after the project funds, instead of 1+ years.</Text>

          <Text as="h3" variant="title03" sx={{ mt: 3 }}>New reward fulfillment process</Text>
          <Text as="p" variant="body">To make things smoother and more convenient, we’re changing how we fulfill Kickstarter rewards for the next campaign. Instead of collecting your address and flat shipping costs in advance, we’ll send backers a unique code to use in our store that will allow them to grab their reserved products whenever they’re ready. No more worrying about late reward surveys! Additionally, because fulfillment will happen through our store, you’ll be able to add additional products to your package when you redeem your Kickstarter reward. Finally, you’ll pay shipping costs accurate to your location at the time of reward fulfillment — allowing us to estimate our funding target more accurately.</Text>
        </Box>
      </Grid>
    </Layout>    
  )
}

export default RoadmapPage